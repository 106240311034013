<template>
	<PopupTwoCol name="tri"
	text="<h3>Éco-station - Le tri des déchets</h3><br/><p>Il y a 4 éco-stations sur le site (au Nord, au Sud, au laboratoire et au magasin général). Des contenants différenciés sont mis à disposition pour permettre aux salariés de recycler un maximum de déchets. Après avoir été récoltés, (bois, carton, ferraille…) ils sont envoyés dans des centres spécialisés qui les revalorisent.</p><br/><p>La raffinerie possède une plateforme de traitement des terres souillées qui permet leur dépollution et leur réutilisation dans l’usine. Les boues issues du traitement des eaux usées et des nettoyages des bacs représentent la partie la plus importante des déchets. Lors de leur destruction, l’énergie produite est utilisée pour faire fonctionner certaines machines de la raffinerie.</p><br/><p>Le recyclage des déchets est très important. Tous les collaborateurs l’intègrent dans leur routine de travail. Le papier est trié dans les bureaux puis collecté et une démarche zéro plastique a été mise en place aux machines à café. Au restaurant d’entreprise, tous les déchets sont triés grâce à une table de tri (les bio-déchets, le pain et les canettes). En 2018, 12,5 tonnes de déchets alimentaires ont été recyclées ou transformées en compost.</p>">
	<picture slot="thumbnail__1">
		<img class="lazy"
		sizes="(max-width: 1366px) 300px,
		(max-width: 1920px) 410px,
		970px"
		data-srcset="
		img/popup/eco_station_Nord_kuod75_c_scale_w_350.jpg 350w,
		img/popup/eco_station_Nord_kuod75_c_scale_w_708.jpg 708w,
		img/popup/eco_station_Nord_kuod75_c_scale_w_970.jpg 970w"
		data-src="img/popup/eco_station_Nord_kuod75_c_scale_w_970.jpg"
		src="static/logo-total.jpg"
		alt="">
	</picture>
	<picture slot="thumbnail__2">
		<img class="lazy"
		sizes="(max-width: 1366px) 300px,
		(max-width: 1920px) 410px,
		970px"
		data-srcset="
		img/popup/Taux_valorisation_kkmfdr_c_scale_w_350.jpg 350w,
		img/popup/Taux_valorisation_kkmfdr_c_scale_w_700.jpg 700w,
		img/popup/Taux_valorisation_kkmfdr_c_scale_w_970.jpg 970w"
		data-src="img/popup/Taux_valorisation_kkmfdr_c_scale_w_970.jpg"
		src="static/logo-total.jpg"
		alt="">
	</picture>
</PopupTwoCol>
</template>

<script>
	export default {
		components: {
			PopupTwoCol: () => import('@/components/PopupTwoCol.vue'),
		},
	}
</script>
